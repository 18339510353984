import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';

import CoreProTable, {
  CoreProTableState,
} from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { OperationType } from '../../core/types/operationType';
import CreditOrderStatusType from '../../domain/order-status/creditOrderType';
import {
  CreditOrder,
  DepositOrderProvider,
} from '../../domain/usdt-providers/depositOrderProvider';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  useCreditStatusTranslate,
  useNotifyStatusTranslate,
} from '../../domain/order-status/orderStatusTranslateHook';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { useTranslation } from 'react-i18next';
import DepositOrderDetail from '../../domain/components/order/DepositOrderDetail';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import ChangeOrderFrom, {
  ChangeOrderFinishEvent,
} from '../component/ChangeOrderFrom';
import { EventProvider } from '../../../event/eventProvider';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { ADJUST_PAYMENT, DEPOSIT_CALLBACK } from '../configs/scopes';
import _module from '../configs/module';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import { CheckDepositHashFrom } from '../../orders/components/CheckDepositHashFrom';
import { PlusOutlined } from '@ant-design/icons';
import { ToBeVerifiedDepositTxnInfo } from '../component/ToBeVerifiedDepositTxnInfo';

// Page state
interface PageOp extends CoreProTableState<CreditOrder> {
  formType?: OperationType;
  formPayload?: any;
}

enum EventType {
  NotifyFinish,
}

const eventSubject = new Subject<{ type: EventType }>();

// eslint-disable-next-line
export default (props: { title: string; subTitle: string }) => {
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    collapsed: false,
    needRequest: true,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    params: {},
  });
  const { t } = useTranslation();
  const loggger = useOperatorLogger();
  // Refs
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();

  const [checkFormVisible, setCheckFormVisible] = useState<boolean>(false);
  const creditStatusEnum = useCreditStatusTranslate();
  const notifyStatusEnum = useNotifyStatusTranslate();
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });
  const [changeOrder, setChangeOrder] = useState<{
    visible: boolean;
    order?: CreditOrder;
  }>({
    visible: false,
    order: undefined,
  });
  const resendNotify = async (row: CreditOrder) => {
    try {
      await DepositOrderProvider.triggerReNotify(row).then((rs) => {
        loggger.log({
          action: '充值通知',
          payload: { req: row, res: rs },
          systemId: row.systemId,
        });
        Modal.success({
          title: '成功通知',
          content: (
            <ul>
              <li>status: {(notifyStatusEnum as any)[rs.notifyStatus]}</li>
              <li>response: {rs.notifyResponse}</li>
            </ul>
          ),
          okText: '確認',
        });
      });
    } catch (e) {
      loggger.log({
        action: '充值通知',
        payload: { req: row, res: e },
        systemId: row.systemId,
      });
      console.error(e);
    } finally {
      eventSubject.next({ type: EventType.NotifyFinish });
    }
  };

  const changeButtonVisible = (row: CreditOrder): boolean => {
    if (row.depositMode < 3) {
      return false;
    }
    return (
      row.status === CreditOrderStatusType.OrderStatusTypes.Pending ||
      row.status === CreditOrderStatusType.OrderStatusTypes.Expired
    );
  };

  const refresh = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
  };

  const [depositTxn, setDepositTxn] = useState<any>({ visible: false });

  /// Effects
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
    // eslint-disable-next-line
    eventSubject
      .pipe(filter((x) => x.type === EventType.NotifyFinish))
      .subscribe(() => {
        refresh();
      });
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === ChangeOrderFinishEvent.symbol))
      .subscribe((x) => {
        setChangeOrder({ visible: false, order: undefined });
        refresh();
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageHeader title={props.title} subTitle={props.subTitle} extra={<></>} />
      <CoreProTable<CreditOrder>
        bordered
        rowKey="orderNo"
        actionRef={actionRef as any}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        updateTableState={(state: any) =>
          pageOp.update((v) => ({ ...v, ...state }))
        }
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        // requestData={async (params: any) => {
        //   return DepositOrderProvider.getAdminCreditOrder({
        //     ...params,
        //     ...dateRangeColumn.getParamAndUpdatePage(),
        //   });
        // }}
        requestData={(params) =>
          DepositOrderProvider.getAdminCreditOrder({
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
          }).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params },
              systemId: params.systemId,
            });
            return rs;
          })
        }
        toolBarRender={() => [
          <Button
            type="primary"
            children="待驗證hash"
            onClick={() => setDepositTxn({ visible: true })}
          />,
          <Button
            key={1}
            type="primary"
            onClick={() => setCheckFormVisible(true)}
          >
            <PlusOutlined />
            {t('credit.check_deposit_hash')}
          </Button>,
        ]}
        columns={[
          {
            width: 180,
            dataIndex: 'orderNo',
            hideInSearch: false,
            title: '訂單編號',
            fixed: 'left',
          },
          {
            width: 80,
            dataIndex: 'channel',
            hideInSearch: false,
            align: 'center',
            valueEnum: ChannelValueEnum,
            title: t('payment.channel'),
          },
          {
            width: 80,
            dataIndex: 'token',
            hideInSearch: false,
            align: 'center',
            valueEnum: Token,
            title: t('payment.token'),
          },
          {
            width: 140,
            dataIndex: 'customOrderNo',
            hideInSearch: false,
            title: '客戶訂單編號',
          },
          {
            width: 70,
            dataIndex: 'systemId',
            hideInSearch: false,
            title: '商戶名稱',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            width: 70,
            dataIndex: 'depositMode',
            hideInSearch: true,
            title: '充值模式',
            valueEnum: CreditOrderStatusType.DepositMode2Chinese,
          },
          {
            width: 70,
            dataIndex: 'status',
            hideInSearch: false,
            title: '訂單狀態',
            valueEnum: creditStatusEnum,
          },
          {
            width: 70,
            dataIndex: 'notifyStatus',
            hideInSearch: true,
            valueEnum: notifyStatusEnum,
            title: '通知狀態',
          },
          {
            width: 90,
            dataIndex: 'merchantUserId',
            hideInSearch: false,
            title: '使用者代號',
            ellipsis: true,
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: false,
            width: 150,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            width: 150,
            dataIndex: 'walletAddress',
            hideInSearch: true,
            align: 'right',
            title: t('payment.wallet_address'),
            render: (n, row) => (
              <HexLabel hex={row.walletAddress} size={'small'} />
            ),
          },
          {
            width: 110,
            dataIndex: 'orderAmount',
            hideInSearch: false,
            valueType: 'text',
            align: 'right',
            title: '訂單金額',
            formItemProps: {
              help: '區間 min,max ex: 10,200 (大於10 & 小於200); ,200 (小於200); 10 (大於10)',
            }
          },
          {
            title: t('payment.actual_amount'),
            dataIndex: 'actualAmount',
            hideInSearch: true,
            width: 110,
            align: 'center',
          },
          {
            title: '來源金額',
            dataIndex: 'fromAmount',
            hideInSearch: true,
            width: 110,
            align: 'center',
          },
          {
            title: '來源幣別',
            dataIndex: 'fromCurrency',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: '幣別費率',
            dataIndex: 'exchangeRate',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: '手續費',
            dataIndex: 'fee',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            width: 150,
            dataIndex: 'createdAt',
            hideInSearch: true,
            title: '建立時間',
            render: (_: any, row: CreditOrder) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          dateRangeColumn.column,
          {
            title: '操作',
            key: '_action',
            fixed: 'right',
            align: 'center',
            dataIndex: 'id',
            valueType: 'option',
            width: 250,
            render: (text: any, row: CreditOrder) =>
              // 加入是否通知，如果是以完成通知的狀態要再確認一次
              [
                <Button
                  size={'small'}
                  key="editable"
                  href={row.paymentUrl}
                  target="_blank"
                >
                  {'充值頁'}
                </Button>,
                <Button
                  size={'small'}
                  key="editable"
                  onClick={(e) => {
                    e.preventDefault();
                    loggger.log({
                      action: '查看',
                      payload: { data: row },
                      systemId: row.systemId,
                    });
                    Modal.info({
                      title: '',
                      width: '640px',
                      content: (
                        <DepositOrderDetail order={row} isAdmin={true} />
                      ),
                    });
                  }}
                >
                  {t('common.view')}
                </Button>,
                <Button
                  key={2}
                  type="primary"
                  disabled={!_module.can([DEPOSIT_CALLBACK])}
                  size={'small'}
                  hidden={
                    row.depositMode === 3 &&
                    row.status ===
                      CreditOrderStatusType.OrderStatusTypes.MappingFail
                  }
                  onClick={() =>
                    Modal.confirm({
                      title: '警吿',
                      content: '請確認是否要立即發送充值通知',
                      okText: '確認',
                      onOk: async () => {
                        if (
                          row.notifyStatus ===
                          CreditOrderStatusType.NotifyStatusTypes.NotifySuccess
                        ) {
                          Modal.confirm({
                            title: '警吿',
                            content:
                              '訂單已通知成功，可能導致對方系統錯誤，請確認是否要重新發送',
                            okText: '確認',
                            onOk: async () => {
                              await resendNotify(row);
                            },
                          });
                        } else {
                          await resendNotify(row);
                        }
                      },
                    })
                  }
                >
                  充值通知
                </Button>,
                changeButtonVisible(row) && (
                  <Button
                    key={'changeOrder'}
                    type="primary"
                    disabled={!_module.can([ADJUST_PAYMENT])}
                    size={'small'}
                    onClick={() => {
                      setChangeOrder({ visible: true, order: row });
                    }}
                  >
                    {t('credit.change_order')}
                  </Button>
                ),
              ],
          },
        ]}
      />
      <ChangeOrderFrom
        visible={changeOrder.visible}
        order={changeOrder.order}
        isAdmin={true}
      />
      <CheckDepositHashFrom
        visible={checkFormVisible}
        onClose={(hash?: string) => {
          setCheckFormVisible(false);
          if (!!hash) {
            pageOp.update((v) => {
              v.params.transactionHash = hash;
              return v;
            });
          }
          refresh();
        }}
      />
      <ToBeVerifiedDepositTxnInfo
        {...depositTxn}
        onFinish={() => {
          setDepositTxn({ visible: false });
        }}
      ></ToBeVerifiedDepositTxnInfo>
      ,
    </>
  );
};
