import { adminClientMessage, AdminResErrorType } from '../../../config/adminRequest';
import { Button, Form, Modal, Spin, Tabs } from 'antd';
import React, { RefObject, useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import CoreDrawer from '../../core/components/CoreDrawer';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import * as _ from 'lodash';
import WithdrawSettingProvider, {
  FeeSetting,
  NumberComponentType,
  WithdrawSetting,
} from '../../domain/usdt-providers/withdrawSettingProvider';
import WithdrawTokenSettingFrom from '../../domain/components/setttings/WithdrawTokenSettingFrom';

export enum SystemSettingActionType {
  AddAgent,
  AddNormal,
  Extend,
  Edit,
}

const fromEditErrorModel = (
  err: AdminResErrorType,
  state: { type: string },
) => {
  Modal.error({
    title: state.type,
    content: (
      <div>
        <ul>
          <li>原因: {adminClientMessage.get(err.clientCode) ?? err.message}</li>
          {err.clientCode === 'feeRate.check.error' ? (
            <>
              <li>欄位: {err.data.colName}</li>
              <li>最小值: {err.data.min}</li>
              <li>最大值: {err.data.max}</li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    ),
    okText: '確認',
  });
};

enum ActionType {
  Closed,
}

const formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

const WithdrawSettingForm = (props: {
  type: SystemSettingActionType;
  visible: boolean;
  closeForm: () => void;
  systemId: string;
}) => {
  let keyNumber = 2;
  const [feeRange, setfeeRange] = useState<FeeSetting>({

      ETHEREUM: {
        USDT: {
          max: 0,
          min: 0,
        },
        ETH: {
          max: 0,
          min: 0,
        },
      },
      TRON: {
        USDT: {
          max: 0,
          min: 0,
        },
        TRX: {
          max: 0,
          min: 0,
        },
      },
      SOLANA: {
        USDT: {
          max: 0,
          min: 0,
        },
      },
      BSC: {
        BUSD: {
          max: 0,
          min: 0,
        },
        USDT: {
          max: 0,
          min: 0,
        },
        USDC: {
          max: 0,
          min: 0,
        },
      },
      XRP: {
        XRP: {
          max: 0,
          min: 0,
        },
      },
    },
  );
  const [actionState] = useState(new Subject<ActionType>());
  useEffect(() => {
    actionState.pipe(filter((x) => x === ActionType.Closed)).subscribe((x) => {
      props.closeForm();
    });
    // eslint-disable-next-line
  }, [actionState]);
  const [actionType, setActionType] = useState<SystemSettingActionType>(
    props.type,
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<WithdrawSetting>({
      systemId: '',
      tokenSetting: {
        ETHEREUM: {
          USDT: {
            active: true,
            feeRate: 0,
            minimumAmount: 0,
            withdrawFee: 0,
            withdrawOption: 'rate',
          },
          ETH: {
            active: true,
            feeRate: 0,
            minimumAmount: 0,
            withdrawFee: 0,
            withdrawOption: 'rate',
          },
        },
        TRON: {
          USDT: {
            active: true,
            feeRate: 0,
            minimumAmount: 0,
            withdrawFee: 0,
            withdrawOption: 'rate',
          },
        },
        SOLANA: {
          USDT: {
            active: true,
            feeRate: 0,
            minimumAmount: 0,
            withdrawFee: 0,
            withdrawOption: 'rate',
          },
        },
        BSC: {
          USDT: {
            active: true,
            feeRate: 0,
            minimumAmount: 0,
            withdrawFee: 0,
            withdrawOption: 'rate',
          },
        },
      },
      networkFeeSetting: {
        ETHEREUM: {
          USDT: {
            transferMode: '',
          },
          ETH: {
            transferMode: '',
          },
        },
        TRON: {
          USDT: {
            transferMode: '',
          },
        },
        SOLANA: {
          USDT: {
            transferMode: '',
          },
        },
        BSC: {
          BUSD: {
            transferMode: '',
          },
          USDT: {
            transferMode: '',
          },
          USDC: {
            transferMode: '',
          },
        },
      },
    },
  );
  useEffect(() => {
    if (props.systemId) {
      WithdrawSettingProvider.getFeeRateRange(props.systemId).then((data) => {
        setfeeRange(data);
      });
    }
  }, [props.systemId]);

  const opLogger = useOperatorLogger();

  const successHandle = () => {
    if (formRef.current) formRef.current.resetFields();
    actionState.next(ActionType.Closed);
  };

  const formSave = () => {
    if (formRef.current) {
      switch (actionType) {
        case SystemSettingActionType.Edit:
          if (props.systemId)
            formRef.current.validateFields().then((value) => {

              for (const channel in data.tokenSetting) {
                const tokenList = data.tokenSetting[channel];
                for (const token in tokenList) {
                  data.tokenSetting[channel][token].feeRate = value[`${channel}_${token}_feeRate`] ??
                    data.tokenSetting[channel][token].feeRate;
                  data.tokenSetting[channel][token].minimumAmount = value[`${channel}_${token}_minimumAmount`] ??
                    data.tokenSetting[channel][token].minimumAmount;
                  data.tokenSetting[channel][token].withdrawFee = value[`${channel}_${token}_withdrawFee`] ??
                    data.tokenSetting[channel][token].withdrawFee;
                  data.tokenSetting[channel][token].withdrawOption = value[`${channel}_${token}_withdrawOption`] ??
                    data.tokenSetting[channel][token].withdrawOption;

                  data.networkFeeSetting[channel][token].transferMode = value[`${channel}_${token}_transferMode`] ?? data.networkFeeSetting[channel][token].transferMode;
                  data.networkFeeSetting[channel][token].staticFee = value[`${channel}_${token}_staticFee`] ?? data.networkFeeSetting[channel][token].staticFee;
                }
              }

              const d = JSON.parse(JSON.stringify(data));
              const v = JSON.parse(JSON.stringify(value));
              const req = { ...d, ...v };

              WithdrawSettingProvider.updateSetting(
                { ...d, ...v },
                // metaData.curSystemId!,
              ).then(
                (rss) => {
                  Modal.success({
                    content: '修改成功',
                    okText: '確認',
                    onOk: () => successHandle(),
                  });
                  opLogger.log({
                    action: '修改',
                    payload: {
                      req: req,
                      res: rss,
                    },
                    systemId: props.systemId,
                  });
                },
                (err) => {
                  fromEditErrorModel(err as any, { type: '更新失敗' });
                  opLogger.log({
                    action: '修改',
                    payload: {
                      req: req,
                      res: err,
                    },
                    systemId: props.systemId,
                  });
                },
              );

            });
          break;
      }
    }
  };
  useEffect(() => {
    setActionType(props.type);
  }, [props.type]);

  useEffect(() => {
    if (props.systemId && props.visible) {
      setLoading(true);
      WithdrawSettingProvider.getSetting(props.systemId)
        .then((data) => {
          if (formRef.current != null) {

            const components: NumberComponentType = {};

            for (const channel in data.tokenSetting) {
              const tokenList = data.tokenSetting[channel];
              for (const token in tokenList) {
                components[`${channel}_${token}_feeRate`] =  data.tokenSetting[channel][token].feeRate;
                components[`${channel}_${token}_minimumAmount`] =  data.tokenSetting[channel][token].minimumAmount;
              }
            }
            formRef.current.setFieldsValue(components)
          }
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.systemId, actionType, props.visible]);

  return (
    <>
      <CoreDrawer
        title={'編輯下發設定'}
        width={640}
        visible={props.visible}
        onClose={() => {
          actionState.next(ActionType.Closed);
        }}
        destroyOnClose={true}
        footerbuttons={[
          <Button key={1} onClick={formSave}>
            送出
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            ref={formRef}
            layout='vertical'
            name='systemSettingForm'
            initialValues={data}
          >
            <Tabs type='card'>
              {
                _.map(data.tokenSetting, (channelObj, channel) => {
                  let isTokenActive = false;
                  _.map(channelObj, (tokenObj, token) => {
                    if (tokenObj.active) {
                      isTokenActive = true;
                    }
                  });
                  if (isTokenActive) {
                    keyNumber++;
                    return (
                      <Tabs.TabPane key={keyNumber} tab={channel}>
                        <WithdrawTokenSettingFrom
                          feeRange={feeRange}
                          tokenSetting={data.tokenSetting}
                          channel={channel}
                          data={channelObj}
                          networkFeeSetting={data.networkFeeSetting}
                        />
                      </Tabs.TabPane>
                    );
                  }
                })
              }
            </Tabs>
          </Form>
        </Spin>
      </CoreDrawer>
    </>
  );
};

export default WithdrawSettingForm;
