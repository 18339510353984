import { GlobalSetting, GlobalSettingProvider } from '../../domain/usdt-providers/globalSettingProvider';
import { Button, Card, Form, InputNumber, Modal, PageHeader, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import _module from '../configs/module';
import { WRITE_SYSTEM_SETTING } from '../configs/scopes';
import ProForm from '@ant-design/pro-form';

const inputStyle: React.CSSProperties = { width: 360 };

const GlobalSettingManager = (props: { title: string }) => {
  const [form] = ProForm.useForm<GlobalSetting>();
  const [formState, setFormState] = useState<{ loading: boolean }>({
    loading: true,
  });

  const handleSubmit = async () => {
    const data = await form.validateFields();
    delete data.ETH_TO_USDT_RATE;
    delete data.ETH_TO_USDT_RATE;
    await GlobalSettingProvider.put(data)
      .then((res) => {
        Modal.success({
          content: '更新成功',
          okText: '確認',
        });
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    GlobalSettingProvider.get().then((data) => {
      const setting = data.data;
      form.setFieldsValue(setting);
      setFormState({ loading: false });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader title={props.title} subTitle="" extra={<></>} />
      <Spin spinning={formState.loading}>
        <Content
          style={{
            padding: 24,
            margin: 0,
          }}
        >
          <Form
            form={form}
            name="register"
            scrollToFirstError
            onFinish={handleSubmit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Card title="匯率設定" style={{ marginBottom: 16 }}>
              <Form.Item
                name="CUSTOM_EXCHANGE_RATE"
                label="使用自定義匯率"
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="CNY_TO_USDT_BUY_PRICE"
                label="CNY買入價"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={6}
                  max={7}
                  precision={4}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="CNY_TO_USDT_SELL_PRICE"
                label="CNY賣出價"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={6}
                  max={7}
                  precision={4}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
            </Card>
            <Card title="出款費率設定" style={{ marginBottom: 16 }}>
              <Form.Item
                name="ETH_TO_USDT_RATE"
                label="乙太到 Usdt 費率"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="TRANSFER_THRESHOLD_RATE"
                label="手續費佔轉帳金額百分比"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="TRX_TO_USDT_RATE"
                label="波場到 Usdt 費率"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="SOL_TO_USDT_RATE"
                label="SOL到 Usdt 費率"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="BNB_TO_USDT_RATE"
                label="BNB到 Usdt 費率"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="BNB_TO_USDC_RATE"
                label="BNB到 Usdc 費率"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="BNB_TO_BUSD_RATE"
                label="BNB到 Busd 費率"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
            </Card>
            <Card title="傭金提領設定" style={{ marginBottom: 16 }}>
              <Form.Item
                name="AGENTFUNDING_ETH_FEE"
                label="ERC提領佣金網路費用(USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="AGENTFUNDING_TRX_FEE"
                label="TRC提領佣金網路費用(USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="AGENTFUNDING_SOL_FEE"
                label="SOL提領佣金網路費用(USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="AGENTFUNDING_BNB_FEE"
                label="BNB提領佣金網路費用(USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
            </Card>
            <Card title="輪詢自動歸集模式設定" style={{ marginBottom: 16 }}>
              <Form.Item
                name="AUTO_COLLECT_POLLING_TRX_MIN"
                label="輪詢錢包TRX最小值"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="AUTO_COLLECT_POLLING_TRX_DEPOSIT_AMOUNT"
                label="不足時補充TRX數量"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="AUTO_COLLECT_POLLING_USDT_THRESHOLD"
                label="USDT自動歸集門檻"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
            </Card>
            <Card title="ETH設定" style={{ marginBottom: 16 }}>
              <Form.Item
                name="ETH_COLLECT_BUFFER_AMOUNT"
                label="歸集ETH緩衝金額"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  max={0.005}
                  step={0.0001}
                  precision={6}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="ETH_ESTIMATE_NETWORK_FEE"
                label="乙太代付下發預估礦工費"
                tooltip="此設定值由系統自動更新"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  precision={6}
                  readOnly={true}
                />
              </Form.Item>
            </Card>
            <Card title="商戶錢包自動補TRX機制" style={{ marginBottom: 16 }}>
              <Form.Item
                name="AUTO_MCH_WALLET_TRX_MIN"
                label="商戶錢包TRX最小值"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="AUTO_MCH_WALLET_TRX_DEPOSIT_AMOUNT"
                label="不足時補充TRX數量"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
            </Card>
            <Card title="充值匹配錯誤防範機制" style={{ marginBottom: 16 }}>
              <Form.Item
                name="LOCK_BSC_USD_DEPOSIT_AMOUNT_THRESHOLD"
                label="鎖定充值金額門檻(BSC-USDT,USDC,BUSD)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_BSC_USD_FLOATING_AMOUNT"
                label="鎖定充值上下金額(BSC-USDT,USDC,BUSD)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_ERC_USDT_DEPOSIT_AMOUNT_THRESHOLD"
                label="鎖定充值金額門檻(ERC-USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_ERC_USDT_FLOATING_AMOUNT"
                label="鎖定充值上下金額(ERC-USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_TRC_USDT_DEPOSIT_AMOUNT_THRESHOLD"
                label="鎖定充值金額門檻(TRC-USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_TRC_USDT_FLOATING_AMOUNT"
                label="鎖定充值上下金額(TRC-USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_SOLANA_USDT_DEPOSIT_AMOUNT_THRESHOLD"
                label="鎖定充值金額門檻(SOLANA-USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_SOLANA_USDT_FLOATING_AMOUNT"
                label="鎖定充值上下金額(SOLANA-USDT)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_ERC_ETH_DEPOSIT_AMOUNT_THRESHOLD"
                label="鎖定充值金額門檻(ERC-ETH)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_ERC_ETH_FLOATING_AMOUNT"
                label="鎖定充值上下金額(ERC-ETH)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={6}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_XRP_XRP_DEPOSIT_AMOUNT_THRESHOLD"
                label="鎖定充值金額門檻(XRP-XRP)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
              <Form.Item
                name="LOCK_XRP_XRP_FLOATING_AMOUNT"
                label="鎖定充值上下金額(XRP-XRP)"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ ...inputStyle }}
                  min={0}
                  precision={2}
                  readOnly={!_module.can([WRITE_SYSTEM_SETTING])}
                />
              </Form.Item>
            </Card>

            <Button
              key={1}
              type="primary"
              htmlType="submit"
              onClick={() => {}}
              loading={formState.loading}
              disabled={!_module.can([WRITE_SYSTEM_SETTING])}
            >
              {'更新'}
            </Button>
          </Form>
        </Content>
      </Spin>
    </>
  );
};

export default GlobalSettingManager;
