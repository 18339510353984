import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';
import adminRequest from '../../../config/adminRequest';


export interface SystemSimple {
  systemId: string;
  systemName: string;
  enabled: number;
}

export type SystemRow = {
  systemId: string;
  systemName: string | null;
  merchantType: string;
  parentSystem: string | 'ROOT';
  status: string;
  activeToken: ActiveToken;
}

export type ActiveToken = {
  ETHEREUM: {
    ETH: boolean,
    USDT: boolean,
  },
  TRON: {
    USDT: boolean,
    TRX: boolean,
  },
  SOLANA: {
    USDT: boolean,
  },
  BSC:{
    BUSD: boolean,
    USDT: boolean,
    USDC: boolean,
  },
  XRP: {
    XRP: boolean,
  }
}

export type TokenSetting = {
  [channel: string]: {
    [token: string]: TokenSettingType;
  };
}

export type TokenSettingType = {
  active: boolean,
  feeRate: number,
  minimumAmount: number
}

export const TypeTransferMode = {
  Dynamic: 'Dynamic',
  Static: 'Static',
};

export interface SystemSettingResponseV2 {
  systemId: string;
  parentSystem: string;
  // systemKey: string;
  depositMode: number;
  systemName: string;
  activeToken: ActiveToken;
  merchantType: string;
  lang: string;
}

export interface FeeSetting {
  ETHEREUM: {
    USDT: {
      max: number,
      min: number,
    },
    ETH: {
      max: number,
      min: number,
    },
  },
  TRON: {
    USDT: {
      max: number,
      min: number,
    },
    TRX: {
      max: number,
      min: number,
    },
  },
  SOLANA: {
    USDT: {
      max: number,
      min: number,
    },
  },
  BSC: {
    BUSD: {
      max: number,
      min: number,
    },
    USDT: {
      max: number,
      min: number,
    },
    USDC: {
      max: number,
      min: number,
    },
  },
  XRP: {
    XRP: {
      max: number,
      min: number,
    },
  }
}

export interface CreditSystemSettingResponse {
  systemId: string;
  depositMode: number;
  creditNotifyUrl: string;
}
export interface PayforSystemSettingResponse {
  systemId: string;
  payforNotifyUrl: string;
}

export type TypeSystemSettingAll = Pick<SystemSettingResponseV2,'systemName'|'depositMode'|'merchantType'|'lang'> &
  CreditSystemSettingResponse & PayforSystemSettingResponse;

export default class SystemProvider {

  static async getSystemList(systemId?: string): Promise<SystemSimple[]> {
    const res = await adminRequest.get<AdminResponse<any>>(
      UsdtUri(`/admin/payment/merchant/child-recursive`),
      {
        params: {
          systemId: !!systemId ? systemId : 'ROOT',
        },
      },
    );
    return res.data.data;
  }

  static async adminUpdateSystemVar(data: any, systemId: string) {
    const result = await adminRequest.put<AdminResponse<SystemSettingResponseV2>>(
      UsdtUri('admin/payment/merchant/system'),
      data,
      {
        headers: {
          SYSTEM_ID: systemId,
        },
      },
    );
    return result.data;
  }

  static async getSystemVar(systemId: string): Promise<TypeSystemSettingAll> {
    const res = await Promise.all([
      adminRequest.get<AdminResponse<any>>(
        UsdtUri('merchant/payment/setting/system-variable'),
        { headers: { SYSTEM_ID: systemId } },
      ),
      adminRequest.get<AdminResponse<PayforSystemSettingResponse>>(
        UsdtUri(`merchant/payfor/setting/${systemId}`),
        { headers: { SYSTEM_ID: systemId } },
      ),
      adminRequest.get<AdminResponse<CreditSystemSettingResponse>>(
        UsdtUri(`merchant/credit/setting/${systemId}`),
        { headers: { SYSTEM_ID: systemId } },
      ),
    ]);
    return { ...res[0].data.data, ...res[1].data.data, ...res[2].data.data };
  }

  static async getSystemAdminVar(systemId: string): Promise<SystemSettingResponseV2> {
    const res = await
      adminRequest.get<AdminResponse<any>>(
        UsdtUri(`admin/payment/merchant/system/${systemId}`),
        { headers: { SYSTEM_ID: systemId } },
      );
    // res.data.data.activeToken = JSON.parse(res.data.data.activeToken);
    return { ...res.data.data };
  }

  static async createSystem(data: any, parent?: string) {
    data.parentSystem = parent ?? 'ROOT';
    const res = await adminRequest.post<AdminResponse<SystemSettingResponseV2>>(
      UsdtUri(`/admin/payment/merchant/system/`),
      {
        ...data,
      },
    );
    return res.data;
  }

  static async deactivate(systemId: string) {
    const res = await adminRequest.patch(
      UsdtUri(`/admin/payment/merchant/${systemId}/deactivate`),
    );
    return res.data;
  }

  static async ban(systemId: string) {
    const res = await adminRequest.patch(
      UsdtUri(`/admin/payment/merchant/${systemId}/ban`),
    );
    return res.data;
  }

  static async activate(systemId: string) {
    const res = await adminRequest.patch(
      UsdtUri(`/admin/payment/merchant/${systemId}/activate`),
    );
    return res.data;
  }
}
