import { Cascader } from 'antd';
import React, { useEffect, useState } from 'react';
import SystemProvider from '../../usdt-providers/systemProvider';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../../stores/merge';
import { UserStore } from '../../../../stores/user';
import { CascaderValueType } from 'antd/lib/cascader';

export type TokenSettingType = { value: string, label: string, children: ChildrenType }[];
export type ChildrenType = { value: string, label: string }[];
type TokenId = { channel?: string, token?: string }

const initData = {
  ETHEREUM: {
    ETH: true,
    USDT: true,
  },
  TRON: {
    USDT: true,
  },
  XRP: {
    XRP: true,
  },
  SOLANA: {
    USDT: true,
  },
};
let initCascaderData: TokenSettingType = [];

const ChannelTokenCascader = (props: {
  option?: TokenId,
  admin?: boolean,
  isEmpty?:boolean,
  onChange: (value: { channel?: string, token?: string }) => void
}) => {
  const userInfo = useSelector<GlobalStoreMerge, UserStore>(
    (store) => store.user,
  );
  const [tokenData, setTokenData] = useState(initData);
  const [cascaderData, setcascaderData] = useState(initCascaderData);
  const [curToken, setCurToken] = useState<TokenId>({ channel: undefined, token: undefined });
  useEffect(() => {
    if(props.admin){
      _.map(initData, (channelObj, channel) => {
        _.map(channelObj, (tokenObj, token) => {
          if (tokenObj) {
            if (props.option && props.option.channel !== '') {
              setCurToken({ channel: props.option.channel, token: props.option.token });
              props.onChange({ channel: props.option.channel, token: props.option.token });
            } else {
              setCurToken({ channel: channel, token: token });
              props.onChange({ channel, token });
            }
          }
        });
      });
      setTokenData(initData);
      return;
    }
    SystemProvider.getSystemAdminVar(userInfo.systemId)
      .then((data) => {
        _.map(data.activeToken, (channelObj, channel) => {
          _.map(channelObj, (tokenObj, token) => {
            if (tokenObj) {
              if (props.option && props.option.channel !== '') {
                setCurToken({ channel: props.option.channel, token: props.option.token });
                props.onChange({ channel: props.option.channel, token: props.option.token });
              } else {
                setCurToken({ channel: channel, token: token });
                props.onChange({ channel, token });
              }
            }
          });
        });
        setTokenData(data.activeToken);
        if(props.isEmpty) {
          setCurToken({ channel: undefined, token: undefined });
        }
      })
      .finally(() => {
      });
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    initCascaderData = [];
    _.map(tokenData, (channelObj, channel) => {
      const children: ChildrenType = [];
      _.map(channelObj, (tokenObj, token) => {
        if (tokenObj) {
          children.push(
            {
              value: token,
              label: token,
            },
          );
        }
      });
      if (children.length > 0) {
        initCascaderData.push({
          value: channel,
          label: channel,
          children: children,
        });
      }
    });
    initCascaderData = _.sortBy(initCascaderData, item => item.value);
    setcascaderData(initCascaderData)
  }, [tokenData]);

  function onCascaderChange(value: CascaderValueType) {
    const data = value as string[];
    const channel = data[0];
    const token = data[1];
    setCurToken({ channel, token });
    props.onChange({ channel, token });
  }

  return (
    <>
      {curToken && (
        <Cascader options={cascaderData}
                  value={[curToken.channel??'', curToken.token??'']}
                  onChange={onCascaderChange}
                  style={{ width: `${380}px` }}
                  placeholder='請選擇' />)}
    </>
  );
};

export default ChannelTokenCascader;
